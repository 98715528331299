import { IJwtDecoder } from "app-domain";
import {
  IAppMonitoringClient,
  IGtag,
  IHttpClient,
  IIdentityApi,
} from "app-domain/abstractions";
import axios, { AxiosError } from "axios";
import {
  AuthenticationProps,
  BffChangePasswordDto,
  ChangePasswordDto,
  ErrorDto,
  IHttpResponse,
  ISessionResponse,
  OAuthProps,
  ReceiveConfirmationDto,
  ReceiveConfirmationResponse,
  ReceiveConfirmationTypesBody,
  RecoverAccountBody,
  RecoverAccountBodyResponse,
  TwoFactorPublicResponseErrorDto,
  UserData,
  UserProps,
  UserRecoverPasswordProps,
  IDisconnectSessionResponse,
} from "typing";
import { IdentityDto } from "typing/dto/Identity";
import { getApisUrlFallback, isObjectEmpty } from "../../../utils";
import { EnvsApi } from "../Envs";

interface IErrorUserDoesNotExist {
  error: string;
  // eslint-disable-next-line camelcase
  error_description: string;
}

type UpdatePasswordError = {
  message: string;
};

const contentType = "application/json";

class IdentityApi implements IIdentityApi {
  baseUrl = "";

  baseUrlV2 = "";

  middlewareBaseUrl = "/api/identity/";

  siteUrl = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private gtag: IGtag,
    private envsApi: EnvsApi
  ) {
    this.handleSetAttributes();

    this.appMonitoringClient = appMonitoringClient;

    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const errorStatus = error?.response?.status;

        if (errorStatus && errorStatus !== 400) {
          appMonitoringClient.captureException(error || this.defaultError, {
            level: "error",
            tags: {
              fcx_labs_error_source: "identity_api",
            },
          });
        }

        return Promise.reject(error);
      }
    );
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  private handleSetAttributes() {
    const { data: apisBaseUrl } =
      this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
    const { data: identityBaseUrl } =
      this.envsApi.getRawEnvImmediately("IDENTITY_BASE_URL");
    const fallback = getApisUrlFallback(process.env.HOST_ENV);

    this.baseUrl = identityBaseUrl || `${apisBaseUrl || fallback}identity/`;
    this.baseUrlV2 = `${apisBaseUrl || fallback}bff-site/identity/`;
  }

  private verifyClientEndpoint = () => {
    return `${this.baseUrl}connect/token`;
  };

  verifyClient = async (body: BodyInit) => {
    try {
      return await this.httpClient.usePost<IdentityDto>(
        this.verifyClientEndpoint(),
        body
      );
    } catch {
      return null;
    }
  };

  public async getToken(
    { login, password, cookieIdCart = "", location }: UserProps,
    jwtDecoder: IJwtDecoder
  ): Promise<AuthenticationProps | null> {
    const response = await this.httpClient.usePost<
      UserProps,
      unknown,
      AuthenticationProps
    >(`${this.middlewareBaseUrl}login`, {
      login,
      password,
      cookieIdCart,
      location,
    });

    if (response?.data?.access_token) {
      const { profile_id: profileId = "" } = jwtDecoder.decode<UserData>(
        response.data.access_token
      );

      this.gtag.login("password", profileId);
    }

    if (response?.errors?.Messages) {
      return null;
    }

    return response?.data || null;
  }

  public refreshToken(refreshToken: string): AuthenticationProps | null {
    const response = this.httpClient.usePost<{ refreshToken: string }>(
      `${this.middlewareBaseUrl}token/refresh`,
      {
        refreshToken,
      }
    );

    return response as unknown as AuthenticationProps;
  }

  public async getTokenOAuth(
    { idToken, provider, cookieIdCart, location }: OAuthProps,
    jwtDecoder: IJwtDecoder
  ): Promise<AuthenticationProps | string | null> {
    const response = await this.httpClient.usePost<
      OAuthProps,
      unknown,
      AuthenticationProps | IErrorUserDoesNotExist
    >(`${this.middlewareBaseUrl}login/social`, {
      idToken,
      provider,
      cookieIdCart,
      location,
    });

    const successData = response?.data as AuthenticationProps;

    if (successData?.access_token) {
      const { profile_id: profileId = "" } = jwtDecoder.decode<UserData>(
        successData.access_token
      );
      this.gtag.login(`${provider}`, profileId);

      return successData;
    }

    const invalidUserData = response?.data as IErrorUserDoesNotExist;

    if (invalidUserData?.error === "invalid_grant") {
      return invalidUserData.error;
    }

    return null;
  }

  public async resetPassword(data: UserRecoverPasswordProps): Promise<void> {
    await axios.post(`${this.baseUrl}api/ecommerce-users/reset-password`, data);
  }

  public async recoverPassword(email: string): Promise<string | null> {
    try {
      await axios.post(
        `${this.baseUrl}api/ecommerce-users/reset-password/${email}`
      );
      return "";
    } catch {
      return null;
    }
  }

  private changePasswordEndpoint = () => {
    return `${this.baseUrl}api/ecommerce-users/change-password`;
  };

  private defaultError = "Ocorreu um erro inesperado, tente novamente";

  private changePasswordMappedErrors = (status: string) => {
    return (
      {
        "400":
          "A senha que você informou não é a mesma da sua conta. Por favor, digite sua senha atual.",
        "500": this.defaultError,
      }[`${status}`] || this.defaultError
    );
  };

  changePassword = async (
    accessToken: string,
    { email, currentPassword, newPassword }: ChangePasswordDto
  ) => {
    try {
      const response = (await this.httpClient.usePost<ChangePasswordDto>(
        `${this.changePasswordEndpoint()}`,
        {
          email,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": contentType,
          },
        }
      )) as { status?: number };

      const responseStatus = response?.status?.toString();

      if (responseStatus) {
        const error = this.changePasswordMappedErrors(responseStatus);

        return {
          data: null,
          error,
        };
      }

      return { data: response, error: null };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      return { data: null, error };
    }
  };

  sendEmailConfirmationEndpoint = (emailAddress: string) => {
    return `${this.baseUrl}api/ecommerce-users/resend-email-confirmation/${emailAddress}`;
  };

  sendEmailConfirmation = async (emailAddress: string) => {
    try {
      const response = await this.httpClient.usePost(
        `${this.sendEmailConfirmationEndpoint(emailAddress)}`,
        {},
        {}
      );

      return {
        data: response,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });

      return {
        data: null,
        error,
      };
    }
  };

  emailConfirmationEndpoint = (emailAddress: string, tokenFromUrl: string) => {
    return `${this.baseUrl}api/ecommerce-users/email-confirmation/${emailAddress}/${tokenFromUrl}`;
  };

  emailConfirmation = async (emailAddress: string, tokenFromUrl: string) => {
    try {
      const response = await fetch(
        this.emailConfirmationEndpoint(emailAddress, tokenFromUrl),
        {
          method: "POST",
        }
      );

      return await response?.ok;
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });

      return null;
    }
  };

  receiveConfirmationTypesEndpoint = () => {
    return `${this.baseUrl}api/ecommerce-users/receive-confirmation-types`;
  };

  public receiveConfirmationTypes = async ({
    email,
    cpf,
    jwt,
  }: ReceiveConfirmationDto) => {
    try {
      const response =
        (await this.httpClient.usePost<ReceiveConfirmationResponse>(
          `${this.receiveConfirmationTypesEndpoint()}`,
          {
            email,
            cpf,
          } as ReceiveConfirmationDto,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )) as unknown as ReceiveConfirmationResponse;

      return {
        data: response,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  receiveConfirmationTypesPublicEndpoint = () => {
    return `${this.baseUrl}api/ecommerce-users/public/receive-confirmation-types`;
  };

  public async receiveConfirmationTypesPublic({
    email,
    cpf,
  }: ReceiveConfirmationTypesBody) {
    try {
      const response = (await this.httpClient.usePost(
        `${this.receiveConfirmationTypesPublicEndpoint()}`,
        {
          email,
          cpf,
        }
      )) as unknown as ReceiveConfirmationResponse & { status?: number };

      if (String(response?.status).startsWith("5")) {
        throw new Error("Houve um erro ao tentar verificar a sua conta.");
      }

      if (String(response?.status).startsWith("4")) {
        throw new Error(
          `Conta inexistente, por favor insira um ${
            email ? "email" : cpf ? "cpf" : "email ou cpf"
          } de uma conta existente.`
        );
      }

      return {
        data: response,
        error: null,
        isLoading: false,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });

      return {
        data: null,
        error: {
          isValid: false,
          errors: {
            Messages: [(error as Error).message],
          },
        } as ErrorDto,
        isLoading: false,
      };
    }
  }

  private recoverAccountEndpoint = () => {
    return `${this.middlewareBaseUrl}recover-account`;
  };

  public async recoverAccount({
    email,
    cpf,
    phoneNumber,
    newPassword,
    token,
    faType,
    channel,
    saleChannel = "2",
    location,
  }: RecoverAccountBody) {
    try {
      const response = (await this.httpClient.usePost(
        `${this.recoverAccountEndpoint()}`,
        {
          email,
          cpf,
          phoneNumber,
          newPassword,
          token,
          faType,
          channel,
          saleChannel,
          location,
        }
      )) as unknown as IHttpResponse<RecoverAccountBodyResponse, ErrorDto>;

      return {
        data: response,
        error: null,
        isLoading: false,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });

      return {
        data: null,
        error: error as ErrorDto,
        isLoading: false,
      };
    }
  }

  public updatePassword = async ({
    token,
    faType,
    email,
    phoneNumber,
    cpf,
    channel,
    oldPassword,
    newPassword,
    jwt,
    location,
  }: BffChangePasswordDto) => {
    try {
      const response = (await this.httpClient.usePost<BffChangePasswordDto>(
        `${this.siteUrl}api/profile/change-password`,
        {
          token,
          faType,
          email,
          phoneNumber,
          cpf,
          channel,
          oldPassword,
          newPassword,
          location,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )) as unknown as TwoFactorPublicResponseErrorDto & {
        detail: string;
      };
      if (String(response?.status).startsWith("5")) {
        throw new Error("Houve um erro ao atualizar senha");
      }

      return { data: response };
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });

      return {
        data: null,
        error: error as UpdatePasswordError,
        isLoading: false,
      };
    }
  };

  getSessionEndpoint = () => {
    return `${this.baseUrl}api/sessions`;
  };

  fetchSessionData = async (
    jwt: string
  ): Promise<ISessionResponse[] | null> => {
    try {
      const response = await fetch(this.getSessionEndpoint(), {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": contentType,
        },
      });
      return await response.json();
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });
    }

    return null;
  };

  public async disconnectAllSessions(jwt: string): Promise<boolean> {
    try {
      const response = await fetch(
        `${this.baseUrl}api/sessions/logout-all-devices`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": contentType,
          },
          method: "DELETE",
        }
      );

      if (response.status === 204) {
        return true;
      }
      throw new Error(
        `Failed to delete all session. HTTP status: ${response.status}`
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });
    }
    return false;
  }

  public async disconnectSession(
    refreshToken: string
  ): Promise<IDisconnectSessionResponse> {
    const response = await this.httpClient.usePost(
      `${this.middlewareBaseUrl}logout`,
      {
        refreshToken,
      }
    );
    if (response?.status === 200) {
      return { success: true, message: null };
    }
    return { success: false, message: response?.errors?.message?.[0] || "" };
  }

  public async deleteSession(jwt: string, sessionId: string): Promise<boolean> {
    try {
      const response = await fetch(
        `${this.baseUrl}api/sessions/logout-device?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": contentType,
          },
          method: "DELETE",
        }
      );

      if (response.status === 204) {
        return true;
      }
      throw new Error(
        `Failed to delete session. HTTP status: ${response.status}`
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error, {
        level: "error",
        tags: {
          fcx_labs_error_source: "identity_api",
        },
      });
    }

    return false;
  }
}

export { IdentityApi };
