import { IHttpClient, IJwtDecoder } from "app-domain/abstractions";
import {
  defaultHeaders,
  handleOnErrorRetry,
  handleRefreshUserAccessToken,
} from "application";
import NextError from "next/error";
import useSWR, { BareFetcher, SWRConfiguration } from "swr";
import { AbortSignalWithTimeout, IHttpParams, IHttpResponse } from "typing";
import { JwtDecode } from "../jwt-decode";
import { SentryClient } from "../monitoring";
import { CookiesNext } from "./CookiesNext";

// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
// const accessTokenCookieKey = "@FC:Ecom:Token:Access";

const isPrimitive = (variable: unknown) => {
  return !!(
    variable &&
    (typeof variable === "boolean" ||
      typeof variable === "string" ||
      typeof variable === "number")
  );
};

const getResponseHeaders = (headers: Headers) => {
  return {
    currentPage: headers.get("x-pagination-currentpage"),
    pageSize: headers.get("x-pagination-pagesize"),
    totalPages: headers.get("x-pagination-totalpages"),
    totalResults: headers.get("x-pagination-totalresults"),
  };
};

const formattedResponse = (response: Response) => {
  const isResponseOk = response?.ok;
  const responseStatus = response?.status;
  const responseUrl = response?.url;

  return response
    .json()
    .then((resolvedResponse) => {
      if (Array.isArray(resolvedResponse)) {
        return resolvedResponse.map((resolvedResponseItemList) => ({
          ...resolvedResponseItemList,
          headers: getResponseHeaders(response.headers),
          status: responseStatus,
        }));
      }

      return {
        ...resolvedResponse,
        ...(isPrimitive(resolvedResponse) && { response: resolvedResponse }),
        headers: getResponseHeaders(response.headers),
        status: responseStatus,
      };
    })
    .catch(
      () => {
        if (!isResponseOk) {
          throw new NextError({
            statusCode: responseStatus,
            title: `Erro retornado na request feita em ${responseUrl}`,
          });
        }

        return null;
      }
      // response
      //   .text()
      //   .then((resolvedTextResponse) => resolvedTextResponse)
      //   .catch(() => null)
    );
};

// const accessTokenIsRefreshed: Middleware =
//   (useSWRNext: SWRHook) => (key, fetcher, config) => {
//     const accessToken = CookiesNext.getCookie({ name: accessTokenCookieKey });

//     // const [isHandlingRefreshAccessToken, setIsHandlingRefreshAccessToken] =
//     //   useState(true);
//     // const [newAccessToken, setNewAccessToken] = useState<string | undefined>(
//     //   undefined
//     // );

//     // useEffect(() => {
//     //   const callHandleRefreshUserAccessToken = async () => {
//     //     const { refreshingAccessToken, refreshedAccessToken } =
//     //       await handleRefreshUserAccessToken(
//     //         CookiesNext,
//     //         SentryClient,
//     //         accessToken
//     //       );

//     //     if (
//     //       refreshingAccessToken !== undefined &&
//     //       refreshingAccessToken === false
//     //     ) {
//     //       setIsHandlingRefreshAccessToken(false);

//     //       if (refreshedAccessToken) {
//     //         setNewAccessToken(refreshedAccessToken);
//     //       }
//     //     }
//     //   };

//     //   callHandleRefreshUserAccessToken();
//     // }, [accessToken]);

//     let customFetcher = fetcher;

//     if (fetcher) {
//       customFetcher = (...args: unknown[]) => {
//         return handleRefreshUserAccessToken(
//           CookiesNext,
//           SentryClient,
//           accessToken
//         ).then(() => {
//           return fetcher(...args);
//         });
//       };
//     }

//     return useSWRNext(key, customFetcher, config);
//   };

const jwtDecoder: IJwtDecoder = new JwtDecode();

export async function useFetcher<JSON = never>(
  input: RequestInfo,
  init?: RequestInit,
  condition = true
): Promise<JSON> {
  const accessToken = (init?.headers as IHttpParams)?.Authorization as string;

  const { refreshedAccessToken, cancelRequest } =
    await handleRefreshUserAccessToken(
      CookiesNext,
      SentryClient,
      jwtDecoder,
      accessToken
    );

  if (cancelRequest) {
    return new Promise((resolve) => {
      resolve({
        data: null,
        error: null,
      } as unknown as JSON);
    });
  }

  const response = condition
    ? await fetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          ...defaultHeaders,
          ...(refreshedAccessToken && {
            Authorization: `Bearer ${refreshedAccessToken}`,
          }),
        },
      })
    : null;

  // return response && typeof response === "object"
  //   ? { ...(await response.json()), headers: response.headers }
  //   : null;

  return response ? formattedResponse(response) : null;
}

const applicationJsonStr = "application/json";

const SWRHttpClient: IHttpClient = {
  useGet: <Data = unknown, Error = unknown>(
    url: string,
    params: IHttpParams = undefined,
    condition = true,
    fallbackData: Data | undefined | unknown = undefined
  ) => {
    // const accessToken = (params?.headers as IHttpParams)
    //   ?.Authorization as string;

    const parameters:
      | SWRConfiguration<Data, Error, BareFetcher<Data>>
      | undefined = {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      fallbackData: fallbackData as Data,
      // use: accessToken ? [accessTokenIsRefreshed] : undefined,
      onErrorRetry: (error, key, _, revalidate, { retryCount }) => {
        handleOnErrorRetry({
          url: key,
          retryCount,
          status: (error as unknown as NextError)?.props?.statusCode,
          doRequestAgain: revalidate,
        });
      },
    };

    const useCustomGetFetcher = params
      ? async (input: RequestInfo, customParams: IHttpParams = params) => {
          const customHeaders = customParams?.headers as
            | RequestInit["headers"]
            | undefined;

          const accessToken = (customHeaders as IHttpParams)
            ?.Authorization as string;

          const { refreshedAccessToken, cancelRequest } =
            await handleRefreshUserAccessToken(
              CookiesNext,
              SentryClient,
              jwtDecoder,
              accessToken
            );

          if (cancelRequest) {
            return new Promise((resolve) => {
              resolve({
                data: null,
                error: null,
              } as unknown as JSON);
            });
          }

          const formattedCustomParams = {
            ...customParams,
            headers: {
              ...customHeaders,
              ...defaultHeaders,
              ...(refreshedAccessToken && {
                Authorization: `Bearer ${refreshedAccessToken}`,
              }),
            },
          } as RequestInit;

          const response = await fetch(input, formattedCustomParams);

          return response ? formattedResponse(response) : null;
        }
      : null;

    const {
      data,
      error,
      mutate: trigger,
      isValidating: isLoading,
    } = useSWR<Data, Error>(
      condition ? url : null,
      useCustomGetFetcher || useFetcher,
      parameters
    );

    return {
      data,
      error,
      trigger,
      isLoading,
    } as IHttpResponse<Data, Error>;
  },
  usePost: async <Data = unknown>(
    url: string,
    body: BodyInit | Data,
    params?: IHttpParams,
    condition = true
  ) => {
    return useFetcher(
      url,
      {
        body: JSON.stringify(body),
        method: "POST",
        ...params,
        headers: {
          "Content-Type": applicationJsonStr,
          ...(params?.headers as RequestInit["headers"] | undefined),
          ...defaultHeaders,
        },
        ...(params?.signal && {
          signal: (AbortSignal as unknown as AbortSignalWithTimeout).timeout(
            Number(params.signal)
          ),
        }),
      },
      condition
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  usePut: async <Data = unknown>(
    url: string,
    header: RequestInit["headers"],
    body: BodyInit | Data | FormData,
    bodyType: "json" | "file" = "json"
  ) => {
    return useFetcher(url, {
      body: bodyType === "json" ? JSON.stringify(body) : (body as FormData),
      method: "PUT",
      headers: {
        ...(bodyType === "json" && { "Content-Type": applicationJsonStr }),
        ...header,
        ...defaultHeaders,
      },
    })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  },
  usePatch: async <Data = unknown>(
    url: string,
    header: RequestInit["headers"],
    body: BodyInit | Data
  ) => {
    return useFetcher(url, {
      body: JSON.stringify(body),
      method: "PATCH",
      headers: {
        "Content-Type": applicationJsonStr,
        ...header,
        ...defaultHeaders,
      },
    })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  },
  useDelete: async <Data = unknown>(
    url: string,
    header: RequestInit["headers"],
    body: BodyInit | Data,
    condition = true
  ) => {
    return useFetcher(
      url,
      {
        body: JSON.stringify(body),
        method: "DELETE",
        headers: {
          "Content-Type": applicationJsonStr,
          ...header,
          ...defaultHeaders,
        },
      },
      condition
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // console.log(error);
        return error;
      });
  },
};

export { SWRHttpClient };
